import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";

import {
  useTable,
  TableHeadCustom,
  TablePaginationCustom,
  TableSelectedAction,
} from "../../../../components/table";

import Scrollbar from "../../../../components/scrollbar";

import UsersTableRow from "./UsersTableRow";

import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import LoadingTableRows from "../../../../components/loadingSkeleton/LoadingTableRows";

import { IconButton, Tooltip } from "@mui/material";

import Iconify from "../../../../components/iconify";

import { getEmployeeServiceList } from "../../../../redux/services/users_service";
// ----------------------------------------------------------------------

// --------- Style ------------

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: "auto",
  overflow: "unset",
  borderColor: theme.palette.primary.main,
  border: "1.99262px 1.99262px 9.9631px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  background: "#fff",
}));

const tableHolderSx = {
  minWidth: 800,
};

const tableContainerStyle = {
  position: "relative",
  overflow: "unset",
};

// ----------------------------------------------------------------------

export default function UsersList({ searchValue }) {
  const dispatch = useDispatch();

  const {
    dense,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  //

  const { employeesList, isLoading, usersColumnsList, count } = useSelector(
    (state) => state.users
  );

  const [tableData, setTableData] = useState([]);

  const [page, setPage] = useState(1);

  const selectedColumns = usersColumnsList.filter((item) => item.status);
  const TABLE_HEAD = [
    ...selectedColumns.map((item) => ({
      id: item.id,
      label: item.name,
      align: "center",
    })),
    { id: "", label: "", align: "center" },
  ];

  const handleChange = (event, value) => {
    setPage(value);
    dispatch(
      getEmployeeServiceList({ page: value || 1, search_value: searchValue })
    );
  };

  useEffect(() => {
    setTableData(employeesList);
  }, [employeesList]);

  return (
    <>
      <StyledTableContainer>
        <TableSelectedAction
          dense={dense}
          numSelected={selected?.length}
          rowCount={tableData?.length}
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              tableData?.map((row) => row?.id)
            )
          }
          action={
            <Tooltip title="حذف">
              <IconButton
                color="#FFFFF"
                //  onClick={handleDelete}
              >
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Tooltip>
          }
        />{" "}
        <TableContainer sx={tableContainerStyle}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ ...tableHolderSx }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
                numSelected={selected.length}
                onSort={onSort}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData?.map((row) => row.id)
                  )
                }
              />

              <TableBody>
                <>
                  {!isLoading ? (
                    tableData?.map((row) => (
                      <UsersTableRow
                        key={row.id}
                        row={row}
                        onSelectRow={() => onSelectRow(row.id)}
                        selected={selected}
                        selectedColumns={selectedColumns}
                      />
                    ))
                  ) : (
                    <LoadingTableRows number={5} cellsNum={5} />
                  )}
                </>
              </TableBody>
            </Table>
          </Scrollbar>
          {tableData?.length > 0 && (
            <TablePaginationCustom
              count={count}
              page={page}
              currentPage={1}
              handleChange={handleChange}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              dense={dense}
              onChangeDense={onChangeDense}
            />
          )}
        </TableContainer>
      </StyledTableContainer>
    </>
  );
}
