import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// POST =>  CREATE MATTER
export const createMatterTypeService = createAsyncThunk(
  "matter/create_matter",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createMatterTypeURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// EDIT =>  EDIT MATTER
export const editMatterService = createAsyncThunk(
  "matter/editMatterService",
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.post(`${URLS.updateMatterURL}${id}/`, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET MATTER SERVICE
export const getMatterTypeService = createAsyncThunk(
  "matter/getMatterType",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMatterTypeURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET MATTER STATUSE
export const getMatterStatusService = createAsyncThunk(
  "matter/getMatterStatus",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMatterStatusURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE MATTER STATUSE
export const createMatterStatusService = createAsyncThunk(
  "matter/createMatterStatus",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createMatterStatusURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE MATTER SESSION
export const craeteSessionService = createAsyncThunk(
  "matter/craeteSession",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createCourtsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET SESSION
export const getSessionService = createAsyncThunk(
  "matter/getSession",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSessionURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE COURTS
export const createCourtsService = createAsyncThunk(
  "matter/createCourts",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createCourtsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET COURTS
export const getCourtsService = createAsyncThunk(
  "matter/getCourts",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getCourtsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE GOVERMENT
export const createGovernmentService = createAsyncThunk(
  "matter/createGovernment",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createGovernmentalURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET GOVERMENT
export const getGovernmentService = createAsyncThunk(
  "matter/getGovernment",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getGovernmentalURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE ATTRIBUTES
export const createAttributesService = createAsyncThunk(
  "matter/createAttributes",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createAttributesURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE SUB ATTRIBUTES
export const createSubAttributesService = createAsyncThunk(
  "matter/createSubAttributes",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createSubAttributesURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET SUB ATTRIBUTE
export const getSubAttributesService = createAsyncThunk(
  "matter/getSubAttributes",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSubAttributesURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE PROCEDURES
export const createProceduresService = createAsyncThunk(
  "matter/createProcedures",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createProceduresURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE SUB PROCEDURES
export const createSubProceduresService = createAsyncThunk(
  "matter/createSubProcedures",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createSubProceduresURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET SUB ATTRIBUTE
export const getSubProcWithSubAttService = createAsyncThunk(
  "matter/getSubProcWithSubAtt",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSubProceduresWithSubURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE EXPENSE
export const createExpenseService = createAsyncThunk(
  "matter/createExpense",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createExpenseURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET EXPENSE
export const getExpenseService = createAsyncThunk(
  "matter/getExpenseService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getExpenseURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE MATTER
export const createMatterService = createAsyncThunk(
  "matter/createMatter",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createMatterURL, data);
      return response;
    } catch (e) {
      console.log("e");
      console.log(e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  GET MATTER
export const getMatterService = createAsyncThunk(
  "matter/getMatter",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMattersURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET INVOICES
export const getInvoiceService = createAsyncThunk(
  "matter/getInvoiceService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getInvoiceURL}${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET EXPENSES MATTER SERVICE
export const getExpensesMatterService = createAsyncThunk(
  "matter/getExpensesMatterService",
  async ({ id, page }, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getExpensesURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE INVOICE
export const createInvoiceService = createAsyncThunk(
  "matter/createInvoiceService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createInvoiceURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE MATTER expense
export const createExpenseMatterService = createAsyncThunk(
  "matter/createExpenseMatterService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createExpenseMatterURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET INVOICES FOR MATTER
export const getInvoicesForMatterService = createAsyncThunk(
  "matter/getInvoiceForMatterService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getInvoicesForMatterURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET  MATTER BY ID
export const getMatterByIdService = createAsyncThunk(
  "matter/getMatterByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getMatterURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST => CREATE CONTRACT
export const createContractService = createAsyncThunk(
  "matter/createContractService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createContractURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE INVOICE
export const createNewInvoiceService = createAsyncThunk(
  "matter/createNewInvoiceService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.addInvoiceURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE Filter
export const createFilterService = createAsyncThunk(
  "matter/createFilterService",
  async (data, thunkAPI) => {
    try {
      console.log("data filter is :", data);
      const response = await axios.post(
        `${URLS.filterMatterURL}?page=${data.page ? data.page : 1}`,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET CONTRACT
export const getContractsService = createAsyncThunk(
  "matter/getContractsService",
  async (matter_id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.getContractMatterURL}/${matter_id}`
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  ADD HOUR SERVICE Filter
export const addHoursService = createAsyncThunk(
  "matter/addHoursService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.addHoursURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  GET HOUR SERVICE Filter
export const getHoursService = createAsyncThunk(
  "matter/getHoursService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getHoursURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// /judgments/
export const getJudgmentsService = createAsyncThunk(
  "matter/getJudgmentsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS?.judgmentsURL, {
        params: {
          judgment_type: params,
        },
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//get Unique Procedures Service
export const getUniqueProceduresService = createAsyncThunk(
  "matter/getUniqueProceduresService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS?.getUniqueProceduresURL, {
        params: {
          search_value: params,
        },
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET CONTRACT FOR CLIENT SERVICE
export const getContractForClientService = createAsyncThunk(
  "matter/getContractForClientService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS?.getContractsForClientURL}/${params?.id}`,
        {
          params: {
            ...params?.data,
          },
        }
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST => ADD MATTER TO CONTRACT
export const addMatterToContractService = createAsyncThunk(
  "matter/addMatterToContractService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.addMatterToContractURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET CONTRACT BY ID
export const getContractByIdService = createAsyncThunk(
  "matter/getContractByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getContractByIdURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//getExpensesForContractURL
export const getExpensesForContractService = createAsyncThunk(
  "matter/getExpensesForContractService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.getExpensesForContractURL}/${id}`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//getExpensesAndInstallmentURL
export const getExpensesAndInstallmentService = createAsyncThunk(
  "matter/getExpensesAndInstallmentService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getExpensesAndInstallmentURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getMatterColumnOptionsService = createAsyncThunk(
  "matter/getMatterColumnOptions",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMatterColumnOptionsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getMatterColumnVisibleService = createAsyncThunk(
  "matter/getMatterColumnVisible",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMatterColumnVisibleURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateMatterColumnVisibleService = createAsyncThunk(
  "matter/updateMatterColumnVisibleService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(URLS.updateMatterVisibleURL, data);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// FOR FILTER

// GET => customize api
export const getMatterCustomizeService = createAsyncThunk(
  "users/getMatterCustomizeService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMatterManagementCustomizeFilterOptionsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET api for user
export const getMatterCustomizeForUserService = createAsyncThunk(
  "users/getMatterCustomizeForUserService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMatterManagementCustomizeFilterForUserURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER Customize for user
export const updateMatterCustomizeForUserService = createAsyncThunk(
  "users/updateMatterCustomizeForUserService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        URLS.updatMatterManagementCustomizeFilterForUserURL,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
