import { Suspense, lazy } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(
  lazy(() => import("../pages/auth/login/LoginPage"))
);

export const WelcomePage = Loadable(
  lazy(() => import("../pages/auth/welcome/WelcomePage"))
);

export const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/reset-password/ResetPassword"))
);

//Office Details
export const OfficeDetails = Loadable(
  lazy(() => import("../pages/setup-wizard/OfficeDetails"))
);

// Add New User Page
export const AddNewUserPage = Loadable(
  lazy(() => import("../pages/setup-wizard/AddNewUserPage"))
);

// Lawsuit Page
export const LawsuitPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/LawsuitPage"))
);

// Create Lawsuit
export const CreateLawsuit = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/CreateLawsuit"))
);

// Edit Lawsuit
export const EditLawsuit = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/EditLawsuit"))
);

// Upload Attachment
export const AttachmentUpload = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/AttachmentUpload"))
);

// Add New Session
export const AddNewSession = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/AddNewSession"))
);

// Add New Expert Session
export const AddExpertSession = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/AddExpertSession"))
);

// Add Adminstration Procedure
export const AddAdminstrationProcedure = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/AddAdminstrationProcedure"))
);

// Add Excutive Procedure
export const AddExcutiveProcedure = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/AddExcutiveProcedure"))
);

// Add  new client
export const AddNewClientPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/AddNewClientPage"))
);

// EDIT CLIENT EditClientPage
export const EditClientPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/EditClientPage"))
);

// edit sessions
export const EditSessionPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/EditSessionPage"))
);

// edit expert
export const EditExpertSession = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/EditExpertSession"))
);

// edit adminstration
export const EditAdminstrationProcedure = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/EditAdminstrationProcedure"))
);

// edit excutive
export const EditExcutiveProcedure = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/EditExcutiveProcedure"))
);

// statment page
export const StatementsPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/StatementsPage"))
);

//ReceiptVoucherPage
export const ReceiptVoucherPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/ReceiptVoucherPage"))
);

//InvoicesPage
export const InvoicesPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/InvoicesPage"))
);

//CreateInvoicePage
export const CreateInvoicePage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/CreateInvoicePage"))
);

//CreateInvoiceMatterPage
export const CreateInvoiceMatterPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/CreateInvoiceMatterPage"))
);

//CreateNewOpponents
export const CreateNewOpponents = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/CreateNewOpponents"))
);

//RollPage
export const RollPage = Loadable(
  lazy(() => import("../pages/dashboard/reports-section/RollPage"))
);

//RollGeneration
export const RollGeneration = Loadable(
  lazy(() => import("../pages/dashboard/reports-section/RollGeneration"))
);

//ClientsPage
export const ClientsPage = Loadable(
  lazy(() => import("../pages/dashboard/clients-section/ClientsPage"))
);

//AddNewUser
export const AddNewUser = Loadable(
  lazy(() => import("../pages/dashboard/users/AddNewUser"))
);

//OverViewPage

export const OverViewPage = Loadable(
  lazy(() => import("../pages/dashboard/overview/OverViewPage"))
);

// CollectionPage
export const CollectionPage = Loadable(
  lazy(() => import("../pages/dashboard/collection/CollectionPage"))
);

// CollectionPage
export const ClientsCollectionPage = Loadable(
  lazy(() => import("../pages/dashboard/collection/ClientsCollectionsPage"))
);

//CreateInvoice
export const CreateInvoice = Loadable(
  lazy(() => import("../pages/dashboard/collection/CreateInvoice"))
);

//UploadFilePage
export const UploadFilePage = Loadable(
  lazy(() => import("../pages/dashboard/collection/UploadFilePage"))
);

//OpponentPage
export const OpponentPage = Loadable(
  lazy(() => import("../pages/dashboard/collection/OpponentPage"))
);

//CreateProcedures
export const CreateProcedures = Loadable(
  lazy(() => import("../pages/dashboard/collection/CreateProcedures"))
);

//EmployeePage
export const EmployeePage = Loadable(
  lazy(() => import("../pages/dashboard/employee/EmployeePage"))
);

//AddNewEmployee
export const AddNewEmployee = Loadable(
  lazy(() => import("../pages/dashboard/employee/AddNewEmployee"))
);

//EditEmployee
export const EditEmployee = Loadable(
  lazy(() => import("../pages/dashboard/employee/EditEmployee"))
);

//EditProcedures
export const EditProcedures = Loadable(
  lazy(() => import("../pages/dashboard/collection/EditProcedures"))
);

//TasksProceduresPage
export const TasksProceduresPage = Loadable(
  lazy(() => import("../pages/dashboard/collection/TasksProceduresPage"))
);

//PersonalProfilePage
export const PersonalProfilePage = Loadable(
  lazy(() => import("../pages/dashboard/personal-profile/PersonalProfilePage"))
);

//AddNewDeptClientPage
export const AddNewDeptClientPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/AddNewDeptClientPage"))
);

//VacationsPage
export const VacationsPage = Loadable(
  lazy(() => import("../pages/dashboard/employee/VacationsPage"))
);

//SessionsPage
export const SessionsPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/SessionsPage"))
);

//CreateStetment
export const AccountsPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/AccountsPage"))
);

//AccountStatement
export const AccountStatement = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/AccountStatement"))
);

//GeneralExpensesPage
export const GeneralExpensesPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/GeneralExpensesPage"))
);

//CollectionFinancesPage
export const CollectionFinancesPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/collection-finance/CollectionFinancesPage")
  )
);

//ExpenseStatementPage
export const ExpenseStatementPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/collection-finance/ExpenseStatementPage")
  )
);

//NewExpenseStatementPage
export const NewExpenseStatementPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/collection-finance/CustodyExpensesPage")
  )
);

//ContactsPage
export const ContactsPage = Loadable(
  lazy(() => import("../pages/dashboard/contacts/ContactsPage"))
);

//TaskProcedurePage
export const TaskProcedurePage = Loadable(
  lazy(() => import("../pages/dashboard/task-procedures/TaskProcedurePage"))
);

//
export const CustodyExpensesPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/CustodyExpensesPage"))
);

//NewStatementsPage
export const NewStatementsPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/NewStatementsPage"))
);

// this is for compny
//NewCompaniesPage
export const NewCompaniesPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/NewCompaniesPage"))
);

//CreateExpenseStatement
export const CreateExpenseStatement = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/CreateExpenseStatement"))
);

// this is for Individual
//NewIndividualsPage
export const NewIndividualsPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/NewIndividualsPage"))
);

//CreateIndividualsExpenseStatement
export const CreateIndividualsExpenseStatement = Loadable(
  lazy(() =>
    import("../pages/dashboard/lawsuit/CreateIndividualsExpenseStatement")
  )
);

//StatementCompaniesDetails
export const StatementCompaniesDetails = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/StatementCompaniesDetails"))
);

//StatementIndividualDetails
export const StatementIndividualDetails = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/StatementIndividualDetails"))
);

//GeneralCalender
export const GeneralCalender = Loadable(
  lazy(() => import("../pages/dashboard/calender/GeneralCalender"))
);

//AssetsPage
export const AssetsPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/AssetsPage"))
);

//CreateNewAsset
export const CreateNewAsset = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/CreateNewAsset"))
);

//ContractsPage
export const ContractsPage = Loadable(
  lazy(() => import("../pages/dashboard/contracts/ContractsPage"))
);

//AddnewInvoice
export const AddnewInvoice = Loadable(
  lazy(() => import("../pages/dashboard/contracts/AddnewInvoice"))
);

//ContractDetailsPage
export const ContractDetailsPage = Loadable(
  lazy(() => import("../pages/dashboard/contracts/ContractDetailsPage"))
);

// CreateAttachmentPage
export const CreateAttachmentPage = Loadable(
  lazy(() => import("../pages/dashboard/lawsuit/CreateAttachmentPage"))
);

//ShowDataPage
export const ShowDataPage = Loadable(
  lazy(() => import("../pages/dashboard/reports-section/ShowDataPage"))
);


//ArchiveUsersPage
export const ArchiveUsersPage = Loadable(
  lazy(() => import("../pages/dashboard/users/ArchiveUsers"))
);