import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";

import { Container, Box, Typography, Button, Divider } from "@mui/material";

import { useSettingsContext } from "../../../components/settings";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import UsersList from "../../../sections/setup-wizard/table/users-list";

import { getEmployeeServiceList } from "../../../redux/services/users_service";

import ColumnsAccordionUsers from "../../../sections/dashboard/lawsuit/accordion/ColumnsAccordionUsers";

import LawsuitSearchBar from "../../../sections/dashboard/lawsuit/LawsuitSearchBar";

//_________________________________________________________

//* ------- Styles --------

const lawsuitTextStyle = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#6A8490",
};

const bigFlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  mb: "20px",
};

const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
};

const createLawsuitButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const dividerStyle = {
  mt: "20px",
};
//___________________________________________________________
export default function UsersPage() {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState();

  const { count } = useSelector((state) => state.users);

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const searchHandler = (e) => {
    const search_value = e.target.value;
    setSearchValue(search_value);
    if (search_value.length === 0) {
      dispatch(
        getEmployeeServiceList({
          page: 1,
          page_size: 10,
        })
      );
    } else {
      dispatch(
        getEmployeeServiceList({
          search_value: e.target.value,
          page: 1,
          page_size: 10,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getEmployeeServiceList({ page: 1 }));
  }, [dispatch]);

  //________________________________________

  return (
    <>
      <Helmet>
        <title> قائمة المستخدمين</title>
      </Helmet>

      <Container sx={{}} maxWidth={themeStretch ? false : "xl"}>
        {/** Top Side */}
        <Box sx={bigFlexBoxStyle}>
          <Typography sx={lawsuitTextStyle}>
            قائمة المستخدمين ({count})
          </Typography>

          <Box sx={flexBoxStyle}>
            {/**  اضافة مستخدم جديد */}
            <Button
              fullWidth
              onClick={() =>
                navigate("/dashboard/hr/employee/add-new-employee")
              }
              size="large"
              type="submit"
              variant="Contained"
              sx={createLawsuitButtonStyle}
            >
              موظف جديد
            </Button>
            {/** */}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ColumnsAccordionUsers />

          <LawsuitSearchBar
            placeholder={"البحث عن موظف"}
            handleSearch={searchHandler}
          />
        </Box>

        {/** */}
        <Divider sx={dividerStyle} />

        <Box
          sx={{
            mt: "10px",
            mb: "10px",
          }}
        >
          <UsersList searchValue={searchValue} />
        </Box>
      </Container>
    </>
  );
}
