import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// POST =>  CREATE USER
export const createEmployeeService = createAsyncThunk(
  "users/createEmployeeService",
  async (data, thunkAPI) => {
    console.log("data ==>", data);
    try {
      const response = await axios.post(URLS.createEmployeeURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>  UPDATE USER
export const updateEmployeeService = createAsyncThunk(
  "users/updateEmployeeService",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axios.put(`${URLS.employees}/${id}/update`, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET USER
export const getEmployeeService = createAsyncThunk(
  "users/getEmployee",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getEmployeesURL);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET USER
export const getEmployeeServiceList = createAsyncThunk(
  "users/getEmployeeServiceList",
  async ({ page, search_value }, thunkAPI) => {
    let url = `${URLS.getEmployeeServiceList}?page=${page}`;
    if (search_value) {
      url += `&search_value=${search_value}`;
    }
    try {
      const response = await axios.get(url);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET DELETED EMPLOYEE
export const getDeletedEmployeeService = createAsyncThunk(
  "users/getDeletedEmployeeService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS?.deletedEmployeesListURL, {
        params: { ...params },
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);


// PUT =>  PUT DELETED EMPLOYEE
export const restoreEmployeeService = createAsyncThunk(
  "users/restoreEmployeeService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.put(`${URLS?.employees}/${id}/restore/`);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);



// DELETE =>  DELETE USER
export const deleteEmpployeeService = createAsyncThunk(
  "users/deleteEmpployeeService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`employees/${id}/delete`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET GROUP
export const getGroupService = createAsyncThunk(
  "users/getGroup",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.gitListGroupWithEmployeeURL);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET Users Non Employee
export const getUsersNonEmployeeService = createAsyncThunk(
  "users/getUsersNonEmployee",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getListEmployeesWithoutGroupURL);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  POST GROUP
export const createGroupService = createAsyncThunk(
  "users/createGroup",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.addGroupURL, data);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  REmove GROUP
export const removeUserFromGroupService = createAsyncThunk(
  "users/createGroup",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.removeUserFromGroupURL, data);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  get Employee SideBar

export const getEmployeeSideBarService = createAsyncThunk(
  "users/getEmployeeSideBarService",
  async (client_id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.getEmployeeSideBar}/${client_id}`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  get Employee SideBar

export const requestVacationService = createAsyncThunk(
  "users/requestVacationService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.vacationRequestsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET ALL VACATIONS

export const getAllVacations = createAsyncThunk(
  "users/getAllVacations",
  async (thunkAPI) => {
    try {
      const response = await axios.get(`/vacation-requests`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET Pending VACATIONS
export const getPendingVacations = createAsyncThunk(
  "users/getPendingVacations",
  async (thunkAPI) => {
    try {
      const response = await axios.get(`/vacation-requests/pending/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET Pending VACATIONS
export const vacationReq = createAsyncThunk(
  "users/vacationReq",
  async ({ id, status }, thunkAPI) => {
    try {
      const response = await axios.put(`/vacation-requests/${id}/status/`, {
        status,
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET PROFILE EMP
export const getEmpProfileService = createAsyncThunk(
  "users/getEmpProfileService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`dashboard/profile/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET USERS SERVICE
export const getUsersService = createAsyncThunk(
  "users/getUsersService",
  async ({ page }, thunkAPI) => {
    try {
      const response = await axios.get(`users/?page=${page}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => customize api
export const getSessionCustomizeService = createAsyncThunk(
  "users/getSessionCustomizeService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSessionCustomizeURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => customize api for user
export const getSessionCustomizeForUserService = createAsyncThunk(
  "users/getSessionCustomizeForUserService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSessionCustomizeForUserURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER Customize for user
export const updateSessionCustomizeForUserService = createAsyncThunk(
  "users/updateSessionCustomizeForUserService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        URLS.updateSessioncustomizeUserURL,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => customize api
export const getVisibleColumsCustomizeService = createAsyncThunk(
  "users/getVisibleColumsCustomizeService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getVisibleColumsCustomizeURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => customize api for user
export const getVisibleColumsCustomizeForUserService = createAsyncThunk(
  "users/getVisibleColumsCustomizeForUserService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(
        URLS.getVisibleColumsCustomizeForUserURL
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER Customize for user
export const updateVisibleColumsCustomizeForUserService = createAsyncThunk(
  "users/updateVisibleColumsCustomizeForUserService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        URLS.updateVisibleColumsCustomizeUserURL,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET MY WORKING HOURS SERVICE
export const getMyWorkingHourService = createAsyncThunk(
  "users/getMyWorkingHourService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMyWorkingHourURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET WEEKLY WORK SERVICE
export const getWeeklyWorkService = createAsyncThunk(
  "users/getWeeklyWorkService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `employee/${params.id}/weekly-work-report/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
