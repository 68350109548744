import * as React from "react";

import { PieChart } from "@mui/x-charts/PieChart";

const colorPalette = [
  "#6a8490", // Base color
  "#7a95a0", // Lighter shade
  "#8aa5b0", // Lighter shade
  "#9ab6c0", // Lighter shade
  "#aab7d0", // Lighter shade
  "#babcdf", // Lighter shade
  "#cbcee0", // Lighter shade
  "#dbe0e1", // Lighter shade
  "#ebf1f1", // Lighter shade
  "#fef4f4", // Very light shade
  "#e0e7e9", // Lighter shade
  "#c4c9ca", // Lighter shade
];

//_______________________________________________________________
export default function CasesPieChart({ data = [] }) {
  // Transform data for PieChart
  const pieData = data?.map((item, index) => ({
    id: index,
    value: item.count,
    label: item.matter_type__name || "Unknown",
  }));
  return (
    <PieChart
      series={[
        {
          data: pieData,
        },
      ]}
      width={600}
      height={400}
      colors={colorPalette}
    />
  );
}
