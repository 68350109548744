import { createSlice } from "@reduxjs/toolkit";

import { getStatisticService } from "../services/statistics_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingStatistic: false,
  statisticList: {},
  matter_counts: [],
  employee_counts_List: [],
};

const slice = createSlice({
  name: "statistic",
  initialState,
  reducers: {},
  extraReducers: {
    // ==> GET STATISTIC
    [getStatisticService.fulfilled]: (state, action) => {
      state.isLoadingStatistic = false;
      state.statisticList = action.payload.data;
      state.matter_counts = action.payload.data.matter_type_counts;
      state.employee_counts_List = action.payload.data?.employee_job_counts;
      console.log("state.matter_counts", state.matter_counts);

      console.log("state.employee_counts_List", state.employee_counts_List);
    },
    [getStatisticService.rejected]: (state, action) => {
      state.isLoadingStatistic = false;
    },
    [getStatisticService.pending]: (state) => {
      state.isLoadingStatistic = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setUsersColumnsList, setIsLoading } = slice.actions;
