import { createSlice } from "@reduxjs/toolkit";
import {
  getInvoicesService,
  createPaymentService,
  createStatementsService,
  createPaymentReceiptService,
  getMattersByClient,
  createFinanceFilterService,
  exportStatementsService,
  getImportExportsService,
  getPaymentReceiptsListService,
  getGeneralExpensesService,
  createGeneralExpensesService,
  sendEmailToClientService,
  getCustudyExpenseService,
  createExpensesCustudy,
  expenseStatementsService,
  createExpenseMatterService,
  checkExpense,
  createStatementsIndividualService,
  createStatementsCompanyService,
  getExpensesMattersService,
  getAllContractsService,
  handOverRequestService,
  getAssetsService,
  getAssetsTypeService,
  createAssetService,
  getInvoiceForContractService,
} from "../services/finance_service";

import {
  invoicesColumnsList,
  voucherColumnsList,
  AccountsColumnsList,
  genralExpenseColumnsList,
  contractColumnList,
  contractClientColumnList,
  invoicesClientColumnsList,
} from "../../utils/columnsLists";
import { toast } from "react-toastify";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingInvoices: false,
  isLoadingCreate: false,
  isLoading: false,
  invoicesList: [],
  //
  isLoadingExpenses: false,
  ExpensesList: [],
  //
  isLoadingCreateInvoice: false,
  //
  isLoadingCreateExpense: false,
  invoice: null,
  invoicesColumnsList: invoicesColumnsList,
  voucherColumnsList: voucherColumnsList,
  isLoadinggetMatters: false,
  mattersByClient: [],
  AccountsColumnsList: AccountsColumnsList,
  count: null,
  total_cost: null,
  dataToExport: {},
  generalExpenseColumnsList: genralExpenseColumnsList,
  paymentRecipitsList: [],
  generalExpenseList: [],
  total: null,
  expensesCount: null,
  invoiceId: null,
  isLoadingClientEmail: false,
  invoicesCount: null,
  custudyExpense: [],
  summery: {},
  custudyExpenseCount: null,
  expenseData: {},
  expenseStatementsCount: null,
  expenseStatements: [],
  isLoadingExSt: false,
  isLoadingCreateExMat: false,
  isLoadingCheck: false,
  ExpenseId: null,
  expensesMatterCount: null,
  expensesClient: null,
  expenses_count: null,
  expenses_status: null,
  expenses_total: null,
  expensesMatter: null,
  allContracts: [],
  columnsContractsList: contractColumnList,
  contractsCount: null,
  columnsContractsClientList: contractClientColumnList,

  invoicesClientColumnsList: invoicesClientColumnsList,
  expenseStatus: null,
  employee: null,
  isLoadingHandOver: false,
  isLoadingReciveRequest: false,
  assetsList: [],
  assetsType: [],
  metaAssets: null,
  contractInvoices: null,
};

const slice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    setInvoceId: (state, action) => {
      state.invoice = action.payload;
    },
    setInvoicesColumnsList: (state, action) => {
      state.invoicesColumnsList = action.payload;
    },
    setVoucherColumnsList: (state, action) => {
      state.voucherColumnsList = action.payload;
    },
    setResetMatterList: (state, action) => {
      state.mattersByClient = [];
    },

    setAccountColumnsList: (state, action) => {
      state.AccountsColumnsList = action.payload;
    },
    setDataToExport: (state, action) => {
      state.dataToExport = action.payload;
    },

    setGeneralExpenseColumnsList: (state, action) => {
      state.generalExpenseColumnsList = action.payload;
    },

    setContractClolumnColumnsList: (state, action) => {
      state.columnsContractsList = action.payload;
    },

    setVoucherId: (state, action) => {
      state.invoiceId = action.payload;
    },
    setExpenseData: (state, action) => {
      state.expenseData = action.payload;
    },
    setExpenseId: (state, action) => {
      state.ExpenseId = action.payload;
    },
    setExpenseStatus: (state, action) => {
      state.expenseStatus = action.payload;
    },
    setEmployee: (state, action) => {
      state.employee = action.payload;
    },

    setContractColumnsList: (state, action) => {
      state.columnsContractsList = action.payload;
    },

    setContractClientColumnsList: (state, action) => {
      state.columnsContractsClientList = action.payload;
    },

    setInvoicesClientColumnsList: (state, action) => {
      state.invoicesClientColumnsList = action.payload;
    },
  },
  extraReducers: {
    // ==> GET INVOICES
    [getInvoicesService.fulfilled]: (state, action) => {
      state.isLoadingInvoices = false;
      state.invoicesList = action.payload?.data?.data;
      state.invoicesCount = action.payload.data?.count;
      console.log("invoicesList", state.invoicesList);
    },
    [getInvoicesService.rejected]: (state, action) => {
      state.isLoadingInvoices = false;
    },
    [getInvoicesService.pending]: (state) => {
      state.isLoadingInvoices = true;
    },

    // ==> CREATE PAYMENT
    [createPaymentService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> CREATE STATEMENT
    [createStatementsService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createStatementsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createStatementsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // POST =>  CREATE PAYMENT RECEPIPT
    [createPaymentReceiptService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentReceiptService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPaymentReceiptService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // GET MATTERS FOR CLIENT
    [getMattersByClient.fulfilled]: (state, action) => {
      console.log("fulfilled");
      state.isLoadinggetMatters = false;
      state.mattersByClient = action.payload?.data?.data;
      state.count = action.payload?.data?.count;
    },
    [getMattersByClient.rejected]: (state, action) => {
      console.log("rejected");
      state.isLoadinggetMatters = false;
    },
    [getMattersByClient.pending]: (state) => {
      console.log("pending");
      state.isLoadinggetMatters = true;
    },

    [createFinanceFilterService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.invoicesList = action.payload.data.data;
      state.invoicesCount = action.payload.data.count;
    },
    [createFinanceFilterService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [createFinanceFilterService.pending]: (state) => {
      state.isLoading = true;
    },

    [exportStatementsService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;

      state.expenses = action.payload.data?.expenses;
      state.count = action.payload?.count;
      state.total_cost = action.payload?.data?.total_cost;
    },
    [exportStatementsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [exportStatementsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    [getImportExportsService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [getImportExportsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [getImportExportsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    //getPaymentReceiptsListService

    [getPaymentReceiptsListService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.paymentRecipitsList = action.payload.data?.data;
      state.count = action.payload.data.count;
    },
    [getPaymentReceiptsListService.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.errors[0] || "حدث خطأ");
    },
    [getPaymentReceiptsListService.pending]: (state) => {
      state.isLoading = true;
    },

    //getPaymentReceiptsListService

    [getGeneralExpensesService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.generalExpenseList = action.payload.data?.data?.general_expenses;
      state.total = action.payload.data?.data?.total;
      state.expensesCount = action.payload.data?.count;
    },
    [getGeneralExpensesService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getGeneralExpensesService.pending]: (state) => {
      state.isLoading = true;
    },

    //createGeneralExpensesService

    [createGeneralExpensesService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createGeneralExpensesService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
      toast.error(action.payload.errors[0] || "حدث خطأ");
    },
    [createGeneralExpensesService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> send email to client
    [sendEmailToClientService.fulfilled]: (state, action) => {
      state.isLoadingClientEmail = false;
    },
    [sendEmailToClientService.rejected]: (state, action) => {
      state.isLoadingClientEmail = false;
    },
    [sendEmailToClientService.pending]: (state) => {
      state.isLoadingClientEmail = true;
    },

    [getCustudyExpenseService.pending]: (state) => {
      state.isLoadingExpenses = true;
    },
    [getCustudyExpenseService.fulfilled]: (state, action) => {
      state.isLoadingExpenses = false;
      state.custudyExpense = action.payload.data.data.data;
      state.custudyExpenseCount = action.payload.data.count;
      state.summery = action.payload.data.data.summery;
    },
    [getCustudyExpenseService.rejected]: (state, action) => {
      state.isLoadingExpenses = false;
    },
    [createExpensesCustudy.fulfilled]: (state, action) => {
      state.isLoadingCreateExpense = false;
    },
    [createExpensesCustudy.rejected]: (state, action) => {
      state.isLoadingCreateExpense = false;
    },
    [createExpensesCustudy.pending]: (state) => {
      state.isLoadingCreateExpense = true;
    },

    // GET =>     EXPORT EXPENS STATMENTS

    [expenseStatementsService.fulfilled]: (state, action) => {
      state.isLoadingExSt = false;
      state.expenseStatements = action.payload.data.data;
      state.expenseStatementsCount = action.payload.data.count;
    },
    [expenseStatementsService.rejected]: (state, action) => {
      state.isLoadingExSt = false;
    },
    [expenseStatementsService.pending]: (state) => {
      state.isLoadingExSt = true;
    },
    // POST =>     CREATE EXPENS STATMENTS

    [createExpenseMatterService.fulfilled]: (state, action) => {
      state.isLoadingCreateExMat = false;
    },
    [createExpenseMatterService.rejected]: (state, action) => {
      state.isLoadingCreateExMat = false;
    },
    [createExpenseMatterService.pending]: (state) => {
      state.isLoadingCreateExMat = true;
    },
    // GET =>     CHECK EXPENS IF EXSIST

    [checkExpense.fulfilled]: (state, action) => {
      state.isLoadingCheck = false;
    },
    [checkExpense.rejected]: (state, action) => {
      state.isLoadingCheck = false;
    },
    [checkExpense.pending]: (state) => {
      state.isLoadingCheck = true;
    },

    // POST =>     CREATE EXPENS STATMENTS
    [createStatementsIndividualService.fulfilled]: (state, action) => {
      state.ExpenseId = action.payload.data.data.id;
      state.isLoadingCreate = false;
    },
    [createStatementsIndividualService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createStatementsIndividualService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    // POST =>     CREATE EXPENS STATMENTS
    [createStatementsCompanyService.fulfilled]: (state, action) => {
      state.ExpenseId = action.payload.data.data.id;
      state.isLoadingCreate = false;
    },
    [createStatementsCompanyService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createStatementsCompanyService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    [getExpensesMattersService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.expensesMatter = action.payload.data.data.expense_matter;
      state.expenses_total = action.payload.data.data.summary.expenses_total;
      state.expenses_status = action.payload.data.data.summary.status;
      state.expenses_count = action.payload.data.data.summary.expenses_count;
      state.expensesClient = action.payload.data.data.summary.client;
      state.expensesMatterCount = action.payload.data.count;
    },
    [getExpensesMattersService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getExpensesMattersService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET =>     GET ALL CONTRACT
    [getAllContractsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allContracts = action?.payload?.data?.data;
      state.contractsCount = action?.payload?.data?.count;
    },
    [getAllContractsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllContractsService.pending]: (state) => {
      state.isLoading = true;
    },

    // PUT =>  HAND OVER TO THE RESPONSIBLE
    [handOverRequestService.fulfilled]: (state, action) => {
      state.isLoadingHandOver = false;
    },
    [handOverRequestService.rejected]: (state, action) => {
      state.isLoadingHandOver = false;
    },
    [handOverRequestService.pending]: (state) => {
      state.isLoadingHandOver = true;
    },

    // GET =>  GET ASSETS SERVICE
    [getAssetsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.metaAssets = action.payload?.data;
      state.assetsList = action.payload?.data?.data;
    },
    [getAssetsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAssetsService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET =>  GET ASSETS SERVICE
    [getAssetsTypeService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.assetsType = action?.payload?.data?.data;
    },
    [getAssetsTypeService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAssetsTypeService.pending]: (state) => {
      state.isLoading = true;
    },

    // CREATE =>  CREATE ASSET SERVICE
    [createAssetService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createAssetService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createAssetService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // CREATE =>  CREATE ASSET SERVICE
    [getInvoiceForContractService.fulfilled]: (state, action) => {
      state.isLoadingInvoices = false;
      state.contractInvoices = action.payload.data?.results;
    },
    [getInvoiceForContractService.rejected]: (state, action) => {
      state.isLoadingInvoices = false;
    },
    [getInvoiceForContractService.pending]: (state) => {
      state.isLoadingInvoices = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setInvoceId,
  setInvoicesColumnsList,
  setVoucherColumnsList,
  setResetMatterList,
  setAccountColumnsList,
  setDataToExport,
  setGeneralExpenseColumnsList,
  setContractClolumnColumnsList,
  setVoucherId,
  setExpenseData,
  setExpenseId,
  setContractColumnsList,
  setContractClientColumnsList,
  setInvoicesClientColumnsList,
  setExpenseStatus,
  setEmployee,
} = slice.actions;
