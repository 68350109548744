import { createSlice } from "@reduxjs/toolkit";
import {
  getCourtsSessionService,
  createCourtsSessionServices,
  updateDelegtionNoteServices,
  filterCourtsService,
  filterExpertsService,
  filterAdministrativeService,
  filterExcuteService,
  getCourtSessionService,
  getProseduersService,
  getAutoCompleteFieldService,
  getAffiliatedAutoCompleteFieldService
} from "../services/courts_service";

import {
  SessionsColumnsList,
} from "../../utils/columnsLists";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingCreate: false,
  courtsSessionsList: [],
  executionSessionsList: [],
  administrativeSessionsList: [],
  expertsSessionsList: [],
  court_sessions: [],
  execution_sessions: [],
  administrative_sessions: [],
  experts_sessions: [],
  CourtSessionColumnsList: [],
  ExpertsSessionColumnsList: [],
  ExcuteSessionColumnsList: [],
  AdministrativeSessionColumnsList: [],

  SessionsColumnsList: SessionsColumnsList,
  // for filter
  expert_sessions: [],
  excute_sessions: [],
  administrative_sessions_filter: [],
  //
  isLoadingExpert: false,
  isLoadingExcute: false,
  isLoadingAdministrative: false,

  // This variable for session list
  isLoadingCourtList: false,
  isLoadingFilter: false,
  isLoadingSessionDetails: false,
  sessionDetails: {},
  isLoadingProcedures: false,
  Procedures: [],
  count: null,
  suggestionsStatment:[],
  suggestionsAffiliated:[]
};

const slice = createSlice({
  name: "courts",
  initialState,
  reducers: {
    setCourtSessionColumnsList: (state, action) => {
   
      state.isLoading = true;
      state.CourtSessionColumnsList = action.payload;
      state.isLoading = false;
    },
    setExpertsSessionColumnsList: (state, action) => {
      state.ExpertsSessionColumnsList = action.payload;
    },
    setAdministrativeSessionColumnsList: (state, action) => {
      state.AdministrativeSessionColumnsList = action.payload;
    },
    setExcuteSessionColumnsList: (state, action) => {
      state.ExcuteSessionColumnsList = action.payload;
    },
    setSessionColumnsList: (state, action) => {
      state.SessionsColumnsList = action.payload;
    },
  },
  extraReducers: {
    // GET =>  All COURTS SESSION
    [getCourtsSessionService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.court_sessions = action.payload.data.results;
      state.execution_sessions = action.payload.data.results;
      state.administrative_sessions = action.payload.data.results;
      state.experts_sessions = action.payload.data.results;
      state.count = action.payload.data.count;
    },
    [getCourtsSessionService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getCourtsSessionService.pending]: (state) => {
      state.isLoading = true;
    },
    // GET =>   COURT SESSION
    [getCourtSessionService.fulfilled]: (state, action) => {
      state.isLoadingSessionDetails = false;
      state.sessionDetails = action.payload.data.data;
    },
    [getCourtSessionService.rejected]: (state, action) => {
      state.isLoadingSessionDetails = false;
    },
    [getCourtSessionService.pending]: (state) => {
      state.isLoadingSessionDetails = true;
    },

    // POST =>  CREATE  SESSION
    [createCourtsSessionServices.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createCourtsSessionServices.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [createCourtsSessionServices.pending]: (state) => {
      state.isLoading = true;
    },

    // POST =>  UPDATE DELEGTION NOTE
    [updateDelegtionNoteServices.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [updateDelegtionNoteServices.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [updateDelegtionNoteServices.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // POST =>  UPDATE DELEGTION NOTE
    [filterCourtsService.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
      state.court_sessions = action.payload.data.data;
      state.count = action.payload.data.count;
      /* state.court_sessions = action.payload.data.court_sessions;
      state.execution_sessions = action.payload.data.execution_sessions;
      state.administrative_sessions =
        action.payload.data.administrative_sessions;
      state.experts_sessions = action.payload.data.experts_sessions;*/
    },
    [filterCourtsService.rejected]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
    },
    [filterCourtsService.pending]: (state) => {
      state.isLoadingFilter = true;
      state.isLoading = true;
    },

    // POST => Filter expert
    [filterExpertsService.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
      state.experts_sessions = action.payload.data.data;
      state.count = action.payload.data.count;
    },
    [filterExpertsService.rejected]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
    },
    [filterExpertsService.pending]: (state) => {
      state.isLoadingFilter = true;
      state.isLoading = true;
    },

    // POST => Filter Excute
    [filterExcuteService.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
      state.execution_sessions = action.payload.data.data;
      state.count = action.payload.data.count;
    },
    [filterExcuteService.rejected]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
    },
    [filterExcuteService.pending]: (state) => {
      state.isLoadingFilter = true;
      state.isLoading = true;
    },

    // POST => Filter Administrative
    [filterAdministrativeService.fulfilled]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
      state.administrative_sessions = action.payload.data.data;
      state.count = action.payload.data.count;
    },
    [filterAdministrativeService.rejected]: (state, action) => {
      state.isLoadingFilter = false;
      state.isLoading = false;
    },
    [filterAdministrativeService.pending]: (state) => {
      state.isLoadingFilter = true;
      state.isLoading = true;
    },
    // GET =>  GET ALL PROSEDUERS LIST
    [getProseduersService.fulfilled]: (state, action) => {
      state.isLoadingProcedures = false;
      state.Procedures = action.payload.data;
    },
    [getProseduersService.rejected]: (state, action) => {
      state.isLoadingProcedures = false;
    },
    [getProseduersService.pending]: (state) => {
      state.isLoadingProcedures = true;
    },

        // GET =>  GET ALL PROSEDUERS LIST
        [getAutoCompleteFieldService.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.suggestionsStatment = action.payload.data?.data;
        },
        [getAutoCompleteFieldService.rejected]: (state, action) => {
          state.isLoading = false;
        },
        [getAutoCompleteFieldService.pending]: (state) => {
          state.isLoading = true;
        },

           // GET =>  GET ALL PROSEDUERS LIST
           [getAffiliatedAutoCompleteFieldService.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.suggestionsAffiliated = action.payload.data?.data;
          },
          [getAffiliatedAutoCompleteFieldService.rejected]: (state, action) => {
            state.isLoading = false;
          },
          [getAffiliatedAutoCompleteFieldService.pending]: (state) => {
            state.isLoading = true;
          },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setCourtSessionColumnsList,
  setExpertsSessionColumnsList,
  setAdministrativeSessionColumnsList,
  setExcuteSessionColumnsList,
  setSessionColumnsList,
} = slice.actions;
