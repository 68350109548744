import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Typography, Tooltip, Link, ListItemText } from "@mui/material";
// locales
import { useLocales } from "../../../locales";

//
import Iconify from "../../iconify";
//
import { StyledItem, StyledIcon } from "./styles";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

NavItem.propTypes = {
  open: PropTypes.bool,
  active: PropTypes.bool,
  item: PropTypes.object,
  depth: PropTypes.number,
  isExternalLink: PropTypes.bool,
};

export default function NavItem({
  item,
  depth,
  hasChild,
  open,
  active,
  isExternalLink,
  ...other
}) {
  const { translate } = useLocales();

  const { permissions } = useSelector((state) => state?.users)

  const hasPermissions = permissions?.includes("finance.view_custodyexpense")

  const { title, path, icon, children, disabled, caption } = item;

  const hasActiveChild = children?.find(
    (child) => child.path === window.location.pathname
  )?.path;

  const activeLink =
    path === window.location.pathname ||
    window?.location?.pathname?.includes(path) ||
    !!hasActiveChild;
  // const activeLink =
  //   path === window.location.pathname ||  !!hasActiveChild


  const renderContent = (
    title !== "عهدة المصروفات" ?
      <StyledItem
        depth={depth}
        active={active}
        disabled={disabled}
        caption={!!caption}
        {...other}
        sx={
          // selectedIndex === item.index
          activeLink
            ? {
              backgroundColor: "#6A8490",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              color: "#6A8490",
              fontSize: hasChild ? "10px !improtatnt" : "5px",
            }
            : {}
        }
      >
        {icon && (
          <StyledIcon
            sx={
              activeLink
                ? {
                  color: "#FFFFFF",
                }
                : {}
            }
          >
            {icon}
          </StyledIcon>
        )}

        <ListItemText
          primary={
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "18px",
                color: activeLink ? "#FFFFFF" : "#6A8490",
              }}
            >
              {translate(title)}
            </Typography>
          }
          secondary={
            caption && (
              <Tooltip title={translate(caption)} placement="top-start">
                <span>{translate(caption)}</span>
              </Tooltip>
            )
          }
          primaryTypographyProps={{
            noWrap: true,
            component: "span",
            color: activeLink ? "#FFFFFF" : "#6A8490",
            sx: {
              color: activeLink ? "#FFFFFF" : "#6A8490",
            },
          }}
          secondaryTypographyProps={{
            noWrap: true,
            variant: "caption",
          }}
        />

        {!!children && (
          <Iconify
            width={16}
            icon={
              open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"
            }
            sx={{ ml: 1, flexShrink: 0 }}
          />
        )}
      </StyledItem> : hasPermissions && <StyledItem
        depth={depth}
        active={active}
        disabled={disabled}
        caption={!!caption}
        {...other}
        sx={
          // selectedIndex === item.index
          activeLink
            ? {
              backgroundColor: "#6A8490",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              color: "#6A8490",
              fontSize: hasChild ? "10px !improtatnt" : "5px",
            }
            : {}
        }
      >
        {icon && (
          <StyledIcon
            sx={
              activeLink
                ? {
                  color: "#FFFFFF",
                }
                : {}
            }
          >
            {icon}
          </StyledIcon>
        )}

        <ListItemText
          primary={
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "18px",
                color: activeLink ? "#FFFFFF" : "#6A8490",
              }}
            >
              {translate(title)}
            </Typography>
          }
          secondary={
            caption && (
              <Tooltip title={translate(caption)} placement="top-start">
                <span>{translate(caption)}</span>
              </Tooltip>
            )
          }
          primaryTypographyProps={{
            noWrap: true,
            component: "span",
            color: activeLink ? "#FFFFFF" : "#6A8490",
            sx: {
              color: activeLink ? "#FFFFFF" : "#6A8490",
            },
          }}
          secondaryTypographyProps={{
            noWrap: true,
            variant: "caption",
          }}
        />

        {!!children && (
          <Iconify
            width={16}
            icon={
              open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"
            }
            sx={{ ml: 1, flexShrink: 0 }}
          />
        )}
      </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    return (
      <Link component={RouterLink} to={path} underline="none">
        {renderContent}
      </Link>
    );
  };

  return <> {renderItem()} </>;
}
